import { Card, CardHeader, CardText, CardTitle, Col, Row } from "react-bootstrap";
import { useState , useEffect  } from "react";
import axios from "axios";
import { CardBody } from "reactstrap";
import imagen from "./intro.jpg"
import vision from "./vision.jpg"
function MisionVision (){
  const [datos , setDatos] = useState([]);
  const [imagenMision , setImagenMision] = useState([]);
  const [imagenVision , setImagenVision] = useState([]);

  // Obtener imágenes de la base de datos al cargar la página
  function ObtenerFotos(){
   axios.get('https://museoprehistorico.com/src/back-end/Fotos/ObtenerFotos.php') // Cambiar la URL al lanzar al servidor
   .then(response => setDatos(response.data))
   .catch(error => console.error('Error fetching images:', error));
  }
  useEffect(() => {
    ObtenerFotos();
  }, []);
  
  useEffect(() => {
    const imagenesTipo3 = datos.filter(imagen => imagen.tipoImagen === '3');  
    setImagenMision(imagenesTipo3[0] || {}); 
    const imagenesTipo4 = datos.filter(imagen => imagen.tipoImagen === '4');  
    setImagenVision(imagenesTipo4[0] || {});
  }, [datos]);

  const estilos={
    maxHeight:'200px',
    width:'300px',
    fitObject:'contain',

  }
  return(
    <div className="p-3 mt-5 ">
      <Row className="w-100">
      <Col md={6}>
        <Card className="mt-2">
         <CardHeader>
           <CardTitle className="fw-bold">Misión</CardTitle>
         </CardHeader>
         <Card.Img  className="m-auto"variant="top" style={estilos}  src={imagen} />
         <CardBody>
           <CardText style={{textAlign:"justify"}}>Misión del Museo Prehistórico: Educar y enseñar de manera didáctica e interactiva sobre la fascinante evolución de los dinosaurios en nuestro planeta y el origen de la vida. Buscamos construir conocimiento mientras compartimos un mensaje apasionado de amor por la naturaleza.</CardText>
          </CardBody>
        </Card>
      </Col>
     
      <Col md={6}>
        <Card className="mt-2">
         <CardHeader>
           <CardTitle className="fw-bold">Visión</CardTitle>
         </CardHeader>
         <Card.Img  className="m-auto"variant="top" style={estilos}  src={vision} />
         <CardBody>
           <CardText style={{textAlign:"justify"}}>El Museo Prehistórico, para el año 2027, se consolidará como uno de los mejores en el departamento del Huila, innovando grandes experiencia, aventura y diversión para sus visitantes de todo el mundo en la época prehistórica.</CardText>
          </CardBody>
        </Card>
      </Col>
     </Row>
    </div>
  )
}

export default MisionVision;
import Footer from './Footer';
import './routescss/Experencias.css';
import Anuncios from './componentes/Carrusel/Anuncios';
import Recuerdos from './componentes/Carrusel/Recuerdos';
import Barra from './Navbar';
import { Col, Row } from 'react-bootstrap';
import Texto from './componentes/Inicio/Text';
import '../../css/index1.css';
import Redes from './componentes/Redes';

export default function Experencias(){
  const contenedorEstilos = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Ajusta la altura según tus necesidades
    overflow: 'hidden', // Evita el desbordamiento de las imágenes
  };

  const iframeEstilos = {
    width: '500px',
    height: '564px',
    border: 'none',
    overflow: 'hidden',
  };

  return(
    <div>
      <Redes/>
      <Barra />
      <div className='contenedor-experencias p-2'>
      <h1 className='imonster text-center fs-1 fw-bold mt-3'>Experencias</h1>
      <div style={contenedorEstilos}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.2766229550066336%26type%3D3&width=500&show_text=true&height=564&appId"
          style={iframeEstilos}
          scrolling="no"
          frameBorder="0"
          allowFullScreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
        <div className='p-5'>
      <Texto texto="Conoce las experiencias de nuestros visitantes. Que esperas para disfrutar la tuya? Da click en Reservar" />
      </div>
        
      </div>
      <Anuncios/>
      <Footer />
    </div>
  )
}
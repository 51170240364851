
function Redes(){
  return(
  <div class="contenedor-iconos">
    <a href="https://www.facebook.com/MuseoPrehistorico" target="_blank">
      <div class="iconos-sociales i1">
      </div>
    </a>
    <a href="https://www.instagram.com/museoprehistorico_neiva/">
      <div class="iconos-sociales i2">
      </div>
    </a>
    <a href="https://wa.me/573142139674?text=¡Hola!.%20Quiero%20recibir%20más%20información%20sobre%20el%20Museo%20Prehistórico">
      <div class="iconos-sociales i3">
      </div>
    </a>         
  </div> 
  )
}
export default Redes;
import { Card, CardBody, CardText} from "react-bootstrap";
import '../../estilos-de-componente/Texto.css';

function Texto ({texto}){
  return(
    <Card className="introduction">
      <CardBody >
        <CardText >
          {texto}
        </CardText>
      </CardBody>
    </Card>
  )
 
}




export default Texto;